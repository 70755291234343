import React from 'react';
import "./index.css"
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom'
import { HashRouter } from 'react-router-dom';
import App from './App';

ReactDOM.render(
  <HashRouter>
    <App/>
  </HashRouter>,
  document.getElementById('root')
);
