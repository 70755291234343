import React from 'react'
import "./OOsAsistenci.css"
import "./Pages.css"
import ExpandMenu from '../components/ExpandMenu'

export default function OOsAsistenci() {
    return (
        <>
            <div className="pages-items asistenca-items">
                <div>
                <ExpandMenu naslov={
                    <h1>
                        Osebna asistenca:
                    </h1>
                    }
                    vsebina={
                        <>
                        <p>
                        Osebna asistenca je zbir storitev, ki ljudem, ki v vsakdanjem življenju potrebujejo 
                        pomoč drugega, omogoča neodvisno in bolj kakovostno življenje. 
                        Storitve so prilagojene konkretnim potrebam posameznega uporabnika 
                        oziroma uporabnice, njegovim oziroma njenim sposobnostim, željam, 
                        pričakovanjem in življenjskim razmeram. Osebno asistenco opravljajo osebni 
                        asistentje, izvajajo pa izvajalci osebne asistence.
                    </p></>} jeOdprto={true}/>
                </div>
                <div>
                    <ExpandMenu naslov={
                    <h1>
                            Zbir storitev, potreb za posameznega uporabnika oziroma uporabnice:
                    </h1>
                    }
                    vsebina={
                    <ul>
                        <ExpandMenu naslov={
                            <h2>
                            Storitve, namenjene osebni pomoči uporabnika oziroma uporabnice:
                            </h2>
                        }
                         vsebina={<>
                            <li>
                            pomoč pri oblačenju in slačenju ter zagotavljanju ustrezne telesne temperature
                        </li>
                        <li>
                                    pomoč pri uporabi stranišča oziroma pri odvajanju
                        </li>
                        <li>
                                    kopanje in pomoč pri umivanju ter vzdrževanju osebne higiene
                        </li>
                        <li>
                                    pomoč pri hranjenju in pitju
                        </li>
                        <li>
                                    pomoč pri gibanju, posedanju, premeščanju, vstajanju ali nameščanju v ustrezen položaj;
                        </li>
                        <li>
                                    pomoč pri namestitvi, upravljanju in uporabi ortopedskih in tehničnih pripomočkov, vodenje in pomoč pri orientaciji slepe in gluhoslepe osebe
                        </li>
                        <li>
                                    obvezna prisotnost pri uporabniku v primerih najtežje invalidnosti, ki zajema čas, v katerem osebni asistent ni neposredno angažiran pri katerih od zgoraj opisanih aktivnosti, je pa njegova prisotnost nujna
                        </li>
                        <li>
                                pomoč pri jemanju zdravil in opravljanju drugih aktivnosti v zvezi z zdravjem, za katere bi se lahko usposobil uporabnik sam in jih tudi opravljal, če ne bi potreboval osebne asistence, saj se za opravljanje storitve ne zahteva posebnih znanj
                        </li>
                        <li>
                                druge storitve, namenjene pomoči pri opravljanju opravil, namenjenih osebni pomoči 
                        </li>

                        </>}/>
                        
                        <ExpandMenu 
                        naslov={
                            <h2>
                            Storitve, namenjene pomoči v gospodinjstvu in drugih dnevnih opravilih:
                            </h2>}
                        vsebina={
                            <>
                                <li>
                                pomoč pri pripravljanju hrane in kuhanju
                                </li>
                                <li>
                                        pomoč pri čiščenju in vzdrževanju bivalnih prostorov (sesanje, pranje perila, likanje, brisanje prahu, čiščenje oken in tal, opravljanje drobnih hišnih opravil, idr.)                          
                                </li>
                                <li>
                                        pomoč pri oskrbi in negi otroka uporabnika/starša ob njegovi prisotnosti in navodilih                            </li>
                                <li>
                                        pomoč pri urejanju osebne dokumentacije
                                </li>
                                <li>
                                    pomoč pri oskrbi hišnih ljubljenčkov
                                </li>
                                <li>
                                    pomoč pri vsakodnevnih nakupih in nakupih predmetov trajne rabe
                                </li>
                                <li>
                                    druge storitve, namenjene pomoči pri opravljanju podpornih dnevnih opravil
                                </li>
                            </>
                        }/>
                        
                        <ExpandMenu naslov={
                        <h2>
                        Spremstvo:
                        </h2>
                        }
                        vsebina={
                            <>
                                <li>
                                vodenje in pomoč pri orientaciji v prostoru
                                </li>
                                <li>
                                    vožnja avtomobila (osebno spremstvo in osebni prevoz)
                                </li>
                                <li>
                                    pri vključevanju v športne, rekreacijsko športne in kulturne dejavnosti
                                </li>
                                <li>
                                    pri ohranjevanju zdravja v zdraviliščih in na drugih krajih
                                </li>
                                <li>
                                    druga pomoč, ki se nanaša na spremstvo v situacijah, kjer uporabnik pri spremstvu potrebuje pomoč
                                </li>
                                <li>
                                    druga pomoč
                                </li>
                            </>
                        }
                        />
                        <ExpandMenu naslov={
                            <h2>
                                Pomoč na delovnem mestu in v izobraževalnem procesu:
                            </h2>}

                            vsebina={
                                <>
                                    <li>
                                        namestitev opreme in pripomočkov ter delovnih in študijskih sredstev
                                    </li>
                                    <li>
                                        pri urejanju in vodenju dokumentacije, branju in pisanju, spremljanje na šolske in izven šolske aktivnosti ter spremljanje na izobraževanja in druge aktivnosti v okviru izobraževalnega ali delovnega procesa
                                    </li>
                                    <li>
                                        pomoč pri uporabi informacijsko komunikacijske tehnologije
                                    </li>
                                    <li>
                                        druga pomoč, ki se nanaša na delovni proces oziroma izobraževanje, ki ga opravlja uporabnik
                                    </li>
                                </>
                            }
                        />
                        
                        <ExpandMenu naslov={
                            <h2>
                                Pomoč pri komunikaciji:
                            </h2>}

                            vsebina={
                                <>
                                    <li>
                                        pri branju besedil v prilagojenih načinih sporazumevanja (knjig, strokovne literature, časopisov, literature po izboru uporabnika)
                                    </li>
                                    <li>
                                        pri komunikaciji preko različnih informacijsko komunikacijskih tehnologij na zahtevo uporabnika
                                    </li>
                                    <li>
                                        prenos vseh informacij iz in v okolje na zahtevo uporabnika
                                    </li>
                                    <li>
                                        tolmačenje in prenos informacij uporabniku na podlagi slikovnega materiala
                                    </li>
                                </>
                            }
                        />
                        
                    </ul>}/>

                    <ExpandMenu naslov={
                    <h1>
                        Uporabnik oziroma uporabnica osebne asistence:
                    </h1>
                    }
                    vsebina={
                    <ul>
                        
                        <ExpandMenu naslov={
                        <h2>
                            Do osebne asistence je ne glede na njegov oz. njen dohodek in premoženje upravičen vlagatelj, ki:
                        </h2>
                        }
                        vsebina={
                            <>
                                <li>
                                    zaradi invalidnosti potrebuje pomoč pri opravljanju aktivnosti, vezanih na samostojno osebno in družinsko življenje, vključevanje v okolje, izobraževanje in zaposlitev
                                </li>
                                <li>
                                    je državljan RS s stalnim bivališčem v Republiki Sloveniji ali tujec, ki ima stalno prebivališče v RS
                                </li>
                                <li>
                                    je star od 18 do 65 let
                                </li>
                                <li>
                                    živi ali bi želel živeti v samostojnem ali skupnem gospodinjstvu zunaj celodnevne institucionalne oskrbe in
                                </li>
                                <li>
                                    potrebuje pomoč najmanj 30 ur tedensko.
                                </li>
                                <p>
                                    Če je vlagatelj imel pravico do osebne asistence pred dopolnjenim 65. letom starosti, je lahko upravičen do osebne asistence tudi potem, ko dopolni to starost.
                                </p>
                                <p>
                                    Četudi gluhi, slepi ali gluhoslepi uporabniku ne potrebuje pomoči osebnega asistenta 30 ur na teden, potrebuje pa samo pomoč pri komunikaciji in spremstvu (komunikacijski dodatek), se mu odobri osebna asistenca v obsegu 30 ur na mesec. Uporabnik pa lahko namesto osebne asistence izbere denarno nadomestilo v višini dodatka za pomoč in postrežbo na pokojninskem in invalidskem zavarovanju.
                                </p>
                                <p>
                                    Vlogo za pravico do osebne asistence ali komunikacijski dodatek z dokazili, potencialni uporabnik ali njegov zakoniti zastopnik vloži na krajevno pristojnem centru za socialno delo. 
                                    V Zavodu MARELA, Turnišče nudimo brezplačno pomoč pri izpolnitvi Vloge za pravico do osebne asistence, za pridobitev Odločbe uporabniku za koriščenje osebne asistence. Pokličite nas po telefonu ali nam pišite na elektronski naslov.
                                </p>
                            </>
                        }
                        />
                    </ul>}/>


                    <ExpandMenu naslov={
                    <h1>
                        Osebni asistent:
                    </h1>
                    }
                    vsebina={
                    <ul>
                        
                        <p>
                            Osebni asistent je fizična oseba, ki opravlja naloge osebne asistence pri izvajalcu osebne asistence. Da pa je osebni asistent lahko zaposlen pri izvajalcu, mora dopolnit 18 let
                        </p>
                        <li>
                            biti poslovno sposoben
                        </li>
                        <li>
                            iz kazenske evidence ne izhaja, da je bil pravnomočno obsojen za kaznivo dejanje na področju nasilja, spolne nedotakljivosti oziroma diskriminatornega ravnanja na podlagi invalidnosti ter
                        </li>
                        <li>
                            ima opravljeno usposabljanje po ZOA.
                        </li>
                        <p>
                            Si želite postati osebni asistent? Pokličite nas po telefonu ali nam pišite na elektronski naslov.
                        </p>
                    </ul>}/>

                    <ExpandMenu naslov={
                    <h1>
                        Izvajalec osebne asistence:
                    </h1>
                    }
                    vsebina={
                        <p>
                        MARELA – Zavod za izvajanje podpornih storitev za samostojno življenje, Turnišče ali krajše Zavod MARELA, Turnišče je eden izmed izvajalcev osebne asistence in deluje na območju celotne Slovenije. Osebni asistent z Zavodom MARELA, Turnišče podpiše pogodbo o zaposlitvi, uporabnik pa Dogovor o izvajanju osebne asistence. Izvajanja osebne asistence financira Republika Slovenija, Ministrstvo za delo, družino, socialne zadeve in enake možnosti. Pokličite nas po telefonu ali nam pišite na elektronski naslov in pomagali vam bomo urediti potrebno dokumentacijo.
                    </p>}/>


                
                </div>
            </div>
        </>
    )
}
