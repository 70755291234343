import React from "react";
import "./Pages.css";
import "./Sodelovanja.css";
import eu from "../IMAGES/eu.png";
import grafika1 from"../IMAGES/grafika1.jpeg"
import grafika2 from"../IMAGES/grafika2.jpeg"
import infografika from"../IMAGES/infografika.jpeg"
import alk from "../IMAGES/alk.png"
import pom from "../IMAGES/pom.png"
import prim from "../IMAGES/prim.png"
import kurjenje from "../IMAGES/kurjenje.jpg"
import ExpandMenu from "../components/ExpandMenu";


export default function Novice() {
  return (
    <div className="pages-items sodelovanja">
      <div>
      <ExpandMenu jeFiksen={true} jeOdprto={true} naslov={<h2>Javni poziv za dodelitev računalniške opreme v izposojo v letu 2024</h2>}
            vsebina={
              <>
                <a href={"https://www.srips-rs.si/vsi-razpisi/razpis/miro"}
                  target="_blank">
                    <h2>Povezava (SRIPS-RS)</h2>
                </a>
            </>
            }/>
        <ExpandMenu jeFiksen={true} jeOdprto={true} naslov={<h1>NIJZ</h1>}
        vsebina={
          <>
            <ExpandMenu naslov={<h2>Varno na soncu</h2>}
            vsebina={
              <>
                <p>Pojavnost rakavih bolezni kože in drugih bolezni zaradi UV-sevanja in visokih temperatur v Sloveniji narašča, zato so strokovnjaki na današnji novinarski konferenci opozorili na pomen preprečevanja izpostavljenosti soncu. </p>
                <p>Dogodek pod sloganom Varno na soncu je organiziralo Ministrstvo za zdravje v sodelovanju s Kliničnim inštitutom za medicino, dela, prometa in športa UKC Ljubljana, Nacionalnim inštitutom za javno zdravje, Dermatovenerološko kliniko UKC Ljubljana, Očesno kliniko UKC Ljubljana, Onkološkim inštitutom Ljubljana in Zvezo slovenskih društev za boj proti raku.</p>
                <p>Predstavljeni so bili izsledki raziskav o škodljivih posledicah sončnega UV-sevanja, pomen zgodnjega odkrivanja kožnega raka ter ukrepi za preprečevanje izpostavljenosti UV-sevanju pri prostočasnih aktivnostih in delu na prostem. Dolgotrajna izpostavljenost UV-sevanju lahko vodi v resna obolenja, nenazadnje tudi v smrt zaradi kožnega raka ali toplotne obremenitve. Po podatkih Mednarodne organizacije dela in Svetovne zdravstvene organizacije je skoraj vsaka tretja smrt zaradi kožnega raka posledica izpostavljenosti UV-sevanju. Zato se moramo pred soncem ustrezno zaščititi.</p>
                <p> več na povezavi: <a href={"https://nijz.si/moje-okolje/varno-na-soncu/"}
                  target="_blank">
                    <h3>Povezava (NIJZ)</h3>
                </a></p>
                <p> video: <a href={"https://www.youtube.com/watch?v=_SB02KOZLbE"}
                  target="_blank">
                    <h3>Povezava (YouTube)</h3>
                </a></p>
            </>
            }/>
            <ExpandMenu naslov={<h2>Bolezni dihal v zimskem času</h2>}
            vsebina={
              <>
                <div className='povezava'>
                  <h2>Streptokokno vnetje žrela</h2>
                  <a href={"https://nijz.si/nalezljive-bolezni/nalezljive-bolezni-od-a-do-z/streptokokno-vnetje-zrela-streptokokna-angina-faringitis-ali-tonzilitis/"}
                    target="_blank">
                      <h2>Povezava (NIJZ)</h2>
                  </a>
                </div>
                <div className='povezava'>
                <h2>Oslovski kašelj</h2>
                <a href={"https://nijz.si/nalezljive-bolezni/nalezljive-bolezni-od-a-do-z/oslovski-kaselj/"}
                  target="_blank">
                    <h2>Povezava (NIJZ)</h2>
                </a>
              </div>
            </>
            }/>
            <ExpandMenu naslov={<h2>Napotki prebivalcem za ravnanje v vročih dneh</h2>}
            vsebina={
              <>
                <p>
                  Vročina v okolju lahko povzroči številne škodljive vplive na zdravje, zlasti pri občutljivejših skupinah prebivalstva.
                  Z upoštevanjem napotkov za ravnanje v obdobju visokih temperatur lahko škodljive vplive omilimo ali preprečimo...
                </p>

                <h2>Več na linku:</h2>
                <li>
                <a target="_blank" href="https://nijz.si/moje-okolje/podnebne-spremembe/napotki-prebivalcem-za-ravnanje-v-vrocih-dneh">
                  https://nijz.si/moje-okolje/podnebne-spremembe/napotki-prebivalcem-za-ravnanje-v-vrocih-dneh
                </a>
                </li>
                <li>
                <a target="_blank" href="https://nijz.si/moje-okolje/zrak/ne-puscajmo-otrok-v-parkiranem-avtomobilu/">
                  https://nijz.si/moje-okolje/zrak/ne-puscajmo-otrok-v-parkiranem-avtomobilu/
                  </a>
                </li>


              </>
            }
            />


            <ExpandMenu naslov={<h2>Aktiven življenjski slog - vsak dan z gibanjem do boljšega zdravja</h2>}
            vsebina={
              <>
                <p>
                  Naše telo je ustvarjeno za gibanje, zato redna telesna dejavnost predstavlja enega izmed temeljev zdravega življenjskega sloga.  
                  Dandanes, ko je sedeč življenjski slog vse bolj razširjen, se posledice upada telesne dejavnosti na človeškem organizmu kažejo na najrazličnejše načine.
                  Za zdravje potrebujemo uravnoteženo, varno in učinkovito telesno dejavnost, ki naj bo tudi primerno dozirana – tako glede intenzivnosti, 
                  pogostosti, raznovrstnosti, kot tudi glede trajanja same dejavnosti.
                </p>
                <h3>Priporočila najdete na  naslednji povezavah:</h3>
                <ul>
                <li>
                <a target="_blank" href="https://www.skupajzazdravje.si/telesna-dejavnost-in-gibanje/telesna-dejavnost-in-aktiven-zivljenjski-slog/">
                  https://www.skupajzazdravje.si/telesna-dejavnost-in-gibanje/telesna-dejavnost-in-aktiven-zivljenjski-slog/
                </a>
                </li>
                <li>
                <a target="_blank" href="https://www.skupajzazdravje.si/telesna-dejavnost-in-gibanje/24-urno-gibalno-vedenje-telesna-dejavnost-sedentarno-vedenje-in-spanje/">
                  https://www.skupajzazdravje.si/telesna-dejavnost-in-gibanje/24-urno-gibalno-vedenje-telesna-dejavnost-sedentarno-vedenje-in-spanje/
                </a>
                </li>
                <li>
                <a target="_blank" href="https://www.skupajzazdravje.si/telesna-dejavnost-in-gibanje/aplikacija-spremljanje-telesne-dejavnosti/">
                  https://www.skupajzazdravje.si/telesna-dejavnost-in-gibanje/aplikacija-spremljanje-telesne-dejavnosti/
                </a>
                </li>
                <li>
                <a target="_blank" href="https://www.skupajzazdravje.si/telesna-dejavnost-in-gibanje/izkoristimo-dan-in-se-cim-vec-gibajmo/">
                  https://www.skupajzazdravje.si/telesna-dejavnost-in-gibanje/izkoristimo-dan-in-se-cim-vec-gibajmo/
                </a>
                </li>
                </ul>
                
               
              </>
            }
            />

          <ExpandMenu naslov={<h2>Kurjenje v pečeh</h2>}
            vsebina={

              <img className="Kurjenje" src={kurjenje}/>

            }
            />

            <ExpandMenu naslov={<h2>Ustno zdravje in ustna higiena pri osebah, ki nosijo proteze</h2>}
            vsebina={
              <>
                <div/>
        
              <p>
                <b>Martin Ranfl</b>, dr.dent.med., spec. , Nacionalni inštitut za javno zdravje OE Murska Sobota
              </p>
              <p>
                Ustno zdravje je neločljiv del splošnega zdravja in pomembno vpliva na kvaliteto življenja
                posameznika. Vsak izmed nas se je že kdaj srečal z zobobolom ali s težavami z dlesnimi. Najpogostejši
                ustni bolezni sta vsekakor karies in vnetje obzobnih tkiv (parodontalna bolezen), obe sta posledica
                delovanja in prisotnosti bakterij v zobnih oblogah. Da preprečimo njihovo nalaganje je potrebno
                redno umivati zobe in opraviti preglede pri zobozdravniku. Vseeno pa včasih napredovanje ustnih
                bolezni ali poškodbe pripeljejo do izgube posameznih ali pa celo vseh zob. Te je mogoče nadomestiti
                z mostički ali implantati, velikokrat pa se nadomestijo s pomočjo protez, ki so snemljivi nadomestki
                izgubljenih zob.
              </p>
              <p>
                Posebno skrb morajo osebe z protezami nameniti preostalim zobem v ustih, saj opravljajo
                pomembno vlogo pri stabilnosti proteze. Zaradi prisotnosti kovinskih elementov, ki držijo delno
                protezo v ustih pa so tudi bolj podvrženi nabiranju zobnih oblog. Zobe je potrebno umivati vsaj
                dvakrat dnevno, uporabljamo mehko zobno ščetko in zobno pasto, ki vsebuje fluoride. Na umivanje
                ustne sluznice pa ne smejo pozabit niti tisti, ki imajo totalno protezo. Z mehko ščetko ali prstom
                ovitim v gazo morajo nežno očistiti trdo nebo, lica in jezik. Ustna higiena pri popolni brezzobosti je
                potrebna za preprečevanje morebitnih vnetij ustne sluznice in preprečevanje slabega zadaha, hkrati
                pa zaradi masažnega učinka povečuje njeno odpornost. Tako osebe, ki nosijo delno ali totalno
                protezo pa morajo skrb nameniti tudi negi proteze.
              </p>
              <p>
                Za higieno protez moramo skrbeti redno, najbolje je, če protezo takoj po jedi umijemo pod tekočo
                hladno vodo. Kadar umivamo protezo pazimo, da nam ne pade iz rok, saj se lahko pri padcu prelomi
                in poškoduje. Na rob umivalnika lahko položimo brisačo, umivalnik pa nekoliko napolnimo z vodo in
                na ta način ublažimo morebiten padec. Protezo je priporočljivo vsaj enkrat dnevno očistiti z mehko
                zobno krtačko. Pri čiščenju lahko uporabimo tudi milnico, ne uporabljamo pa običajnih zobnih past.
                Te namreč vsebujejo grobe delce, ki sicer pomagajo odstranjevati zobne obloge z naravnih zob, a so
                pregrobi za umivanje proteze. Opraskana oziroma poškodovana površina proteze, je bolj dovzetna za
                nabiranje oblog in zabarvanja iz hrane. Izogibamo se tudi uporabi drugih agresivnih sredstev in belil in
                vroče vode, saj tudi to lahko poškoduje zobno protezo. Skrbno očistimo čisto vse površine proteze,
                dosledno odstranimo tudi morebitne ostanke pritrdilne paste. Za dodaten učinek čiščenja občasno
                protezo očistimo tudi s čistilnimi tabletami. Uporabljamo jih v skladu z navodili proizvajalcev, pred
                uporabo pa protezo dobro splahnemo. Kadar proteze ne nosimo jo shranimo v posodo ali kozarec v
                katerem imamo vodo.
              </p>
              <p>
                Pacienti, ki imajo proteze se morajo redno udeleževati kontrolnih pregledov pri zobozdravniku.
                Zaradi anatomskih sprememb v ustni votlini in obrabe proteze se sčasoma spremeni njena
                funkcionalnost in stabilnost. Pri pregledu lahko zobozdravnik ugotovi, da so potrebni določeni
                postopki za izboljšanje teh lastnosti. So pa za izboljšanje nosljivosti proteze na voljo tudi različne
                pritrdilne paste. Za njihovo uporabo se odločimo šele po pregledu in posvetu z zobozdravnikom. Pri
                uporabi pritrdilnih past se držimo navodil proizvajalca in vestno skrbimo za higieno proteze
              </p>
              <p>
                Dosledna skrb za izvajanje ustne higiene prispeva k boljšemu ustnemu zdravju in prihranim
                marsikatero težavo in bolečino. To velja tudi pri posameznikih, ki imajo svoje manjkajoče zobe
                nadomeščene s protezo. Upoštevanje nasvetov pri nosilcih protez namreč omogoča daljše in
                uspešnejše nošenje protez in s tem višjo kvaliteto življenja.
              </p>
              </>
            }
            />
            <ExpandMenu naslov={<h2>Telesna dejavnost</h2>}
            vsebina={
              <>
                <p><a target="_blank" href="https://www.nijz.si/sl/podrocja-dela/moj-zivljenjski-slog/telesna-dejavnost">www.nijz.si/sl/podrocja-dela/moj-zivljenjski-slog/telesna-dejavnost</a></p>
                
              </>
            }
            />
            <ExpandMenu naslov={<h2>Svetovni dan preprečevanja samomora</h2>}
            vsebina={
              <>
                <p>
                  10. septembra obeležujemo Svetovni dan preprečevanja samomora.
                  Samomorilno vedenje lahko prizadene vsakogar. Samomor predstavlja
                  velik javnozdravstveni problem. Preprečevanje samomorilnega vedenja je
                  naloga celotne družbe. Pri tem so pomembna dejanja vseh nas.
                  Obeležitev svetovnega dneva preprečevanja samomora, letos že drugič
                  zapored poteka pod geslom »Ustvarjajmo upanje z dejanji«.
                </p>
                <h3>Pomurje</h3>
                <p>
                  V Sloveniji lahko opazimo zelo velike razlike v umrljivosti zaradi
                  samomora med posameznimi regijami. V regijah na zahodu države je
                  stopnja samomorilnosti nekoliko nižja kot na vzhodu, a je v Pomurju
                  najnižja med regijami vzhodnega dela države.
                </p>
                <h3>Kilometri za življenje</h3>
                <p>
                  Območna enota Nacionalnega inštituta za javno zdravje je tudi letos v
                  sodelovanju s številnimi organizacijami v regiji pripravila akcijo
                  “Kilometri za življenje”, v sklopu katere se zbirajo prekolesarjeni
                  kilometri. Zbiranje kilometrov poteka znotraj akcije “Prekolesarimo
                  svet”. S to aktivnostjo želimo javnost ozaveščati o problematiki
                  samomora, saj je telesna dejavnost učinkovit dejavnik za izboljšanje
                  duševnega zdravja. Akcija se bo pričela 10.9. 2022 ob Svetovnem dnevu
                  preprečevanja samomora in bo trajala do 10.10.2022, Svetovnega dne
                  duševnega zdravja.
                </p>
                <p>
                  Obeležitev svetovnega dne preprečevanja samomora obeležujemo z rumeno
                  oranžnimi pentljami. V ta namen smo skupaj z Mestno občino Murska
                  Sobota pripravili simbolno akcijo, v okviru katere se bo soboški grad
                  osvetlil v oranžno rumene barve.
                </p>
                <h3>Viri pomoči</h3>
                <p>
                  Problematika samomora je občutljiva tema. O tej temi moramo senzibilno
                  ozaveščati javnost. Osebam, ki doživljajo težave v duševnem zdravju in
                  svojcem, ki se srečajo s samomorom so na voljo različne organizacije
                  tudi v naši regiji (Center za duševno zdravje, center za krepitev
                  zdravja, nevladne organizacije…). Seznanjanje javnosti o dostopnih
                  virih pomoči je ključno. Na ta način se posamezniki opolnomočijo in
                  opremijo z ustreznimi informacijami, ki so jim v pomoč, ko le-to
                  potrebujejo.
                </p>
                <p>Bodimo podporno okolje drug drugemu.</p>
                <p>Vir: NIJZ</p>
                <p>Povzela: Judita Sudec, NIJZ OE Murska Sobota</p>
                <img className="infografika" src={infografika}/>
                <img className="grafika1" src={grafika1}/>
                <img className="grafika2" src={grafika2}/>
              </>
            }
            />
            
            <ExpandMenu naslov={<h2>NOSEČNOST BREZ ALKOHOLA: PRVO DARILO STARŠEV SVOJEMU OTROKU</h2>}
            vsebina={
              <>
                <p>
                  9.september je mednarodni dan ozaveščanja o posledicah pitja alkohola
                  med nosečnostjo, tako imenovani dan FAS (fetalnega alkoholnega
                  sindroma). Ta dan je namenjen ozaveščanju javnosti{" "}
                  <b>
                    o škodljivosti alkohola v času nosečnosti, v času načrtovanja
                    nosečnosti in v času dojenja.
                  </b>
                </p>
                <p>
                  Izpostavljenost otroka alkoholu pred rojstvom je lahko vzrok za
                  različne nepravilnosti v razvoju in primanjkljaje, ki otroka potem
                  spremljajo celotno življenje.
                  <b> Otroci s fetalnim alkoholnim sindromom</b>
                  imajo lahko učne težave, motnje v telesnem razvoju in različne
                  vedenjske motnje. Vse to lahko vodi v prikrajšanost na različnih
                  področjih življenja vse od izobraževanja, zaposlitve, samostojnosti,
                  duševnega zdravja, različnih tveganih vedenj… Posamezniki s fetalnim
                  alkoholnim sindromom so zelo raznolika skupina. Motnje in
                  prikrajšanosti so različnih oblik in intenzivnosti, pri nekaterih so
                  prisotne, pri drugih ne.
                </p>
                <p>
                  Leto 2022 je leto mladih, zato letos poudarek dajemo{" "}
                  <b>razmišljanju mladih o alkoholu in nosečnosti.</b>
                  Zavedanje, da pitje pijač, ki vsebujejo alkohol, pred zanositvijo in
                  med nosečnostjo lahko škodi zdravju in dobrobiti potomcev, je še
                  zlasti pomembno med mladimi, saj bodo nekoč verjetno postali starši.
                  Mladi nam sporočajo, da se vsekakor strinjajo, da pitje alkoholnih
                  pijač med nosečnostjo predstavlja problem. Zavedajo se tega, da ko
                  mama zaužije alkohol, ga zaužije tudi nerojeni otrok, kar mu seveda
                  škoduje. In ne samo njemu, tudi mamici, nosečnici, saj vpliva na njeno
                  zdravje in varnost.
                </p>
                <p>
                  Zato mladi sporočajo, da se pred zanositvijo in v času nosečnosti
                  mamice izogibajo alkoholnih pijač. Postavijo otroka na prvo mesto in
                  razmislijo o prihodnosti. Vsekakor pa je nujno, da ob tem ne podležejo
                  pritiskom in vplivu okolice.
                </p>
                <p>
                  <b>Način življenja obeh staršev </b>v obdobju pred in okoli spočetja
                  ter med nosečnostjo pomembno vpliva na otrokovo zdravje skozi celo
                  življenje.
                </p>
                <p>
                  Zagotovo vemo, da v obdobju nosečnosti ne poznamo varne količine
                  alkohola niti varne alkoholne pijače.  Pitje kakršne koli alkoholne
                  pijače ne glede na vrsto pijače, predvsem med nosečnostjo in v času
                  dojenja, je skrajno tvegano. Seveda tudi v času načrtovanja
                  nosečnosti, saj bodoče mamice včasih prve 4 – 6 tednov niti še ne vedo
                  za svojo nosečnost. Otrok je v maternici prek popkovnice povezan z
                  mamo in zato zaužije vse, kar poje in popije mamica.
                </p>
                <p>
                  Pitje večjih količin alkohola med nosečnostjo je znan dejavnik
                  tveganja za številne neugodne izide: spontani splavi, mrtvorojenost,
                  zastoj rasti v maternici, nizka porodna teža in nenadna smrt dojenčka.
                  Tveganje za nekatere neugodne izide poveča že izpostavljenost majhnim
                  količinam alkohola.
                </p>
                <p>
                  Torej ni varne alkoholne pijače, ni varne količine in ni varnega
                  obdobja. Alkohol škodljivo vpliva na razvoj otroka vse od časa zorenja
                  jajčeca in semenčice do rojstva.
                </p>
                <p>
                  Torej&nbsp;
                  <b>
                    ni varne alkoholne pijače, ni varne količine in ni varnega obdobja.
                  </b>{" "}
                  Alkohol škodljivo vpliva na razvoj otroka vse od časa zorenja jajčeca
                  in semenčice do rojstva.
                </p>
                <p>
                  <b>Nosečnost brez alkohola </b>je najboljša in najučinkovitejša
                  zaščita še nerojenih otrok pred izpostavljenostjo alkoholu. Zato se
                  škodi, ki jo povzroča pitje alkohola lahko popolnoma izognemo, če se
                  pitju alkohola v tem občutljivem obdobju popolnoma, torej 100%
                  odpovemo.
                </p>
                <p>
                  Nosečnicam in doječim materam velja nasvet, naj takoj prenehajo s
                  pitjem alkohola. Če same ne zmorejo naj se obrnejo na strokovno pomoč:
                  izbranega zdravnika, ginekologa ali porodničarja, pediatra, socialnega
                  delavca v CSD,… Vire pomoči ob težavah z alkoholom pa lahko najdejo
                  tudi na spletnih straneh NIJZ (
                  <a href="https://www.nijz.si">www.nijz.si</a>), strani projekta SOPA –
                  Skupaj za odgovoren odnos do pitja alkohola (
                  <a href="https://www.sopa.si">www.sopa.si</a>) ali na spletni strani
                  INFOMOSA (<a href="https://www.infomosa.si">www.infomosa.si</a>).
                </p>
                <p>
                  <b>Darilo staršem otroku,</b> ki pred rojstvom ni bil izpostavljen alkoholu
                  <b>je zdrav telesni in duševni razvoj.</b> Odgovornost pa ni le na strani
                  bodoče mamice, ampak so tu tudi očka, stari starši, prijatelji in vsa
                  njena ožja in širša okolica. Zato moramo vsi skupaj podpirati bodoče
                  starše pri tem.
                </p>
                <p>Po <a href="https://www.nijz.si">www.nijz.si</a> povzela Jasmina Črnko Papić</p>
                <img className="prim" src={prim}/>
                <img className="pom" src={pom}/>
                <img className="alk" src={alk}/>
              </>
            }
            />

          </>
        }
        />
        
        
        <ExpandMenu jeFiksen={true} jeOdprto={true}
        
        naslov={<h1>Zaposli.me</h1>}
        vsebina={
          <>
            <p>
            Namen programa je spodbujanje zaposlovanja brezposelnih oseb iz ciljne
            skupine. Omogoča izboljšanje dostopa do čim hitrejše in kakovostne
            zaposlitve ter pomembno prispeva k dvigu njihove zaposljivosti,
            socialnega kapitala, dolgoročni socialni vključenosti ter enakim
            možnostim na trgu dela.
            </p>

            <div>
              <h2>
                Program se izvaja v okviru Operativnega programa za izvajanje evropske
                kohezijske politike v obdobju 2014 – 2020, v okviru:
              </h2>
              <ul>
                <li>                  
                  8. prednostne osi: Spodbujanje zaposlovanja in transnacionalna
                  mobilnost delovne sile,
                </li>
                <li>                
                  8.1. prednostne naložbe: Dostop do delovnih mest za iskalce
                  zaposlitve in neaktivne osebe, vključno z dolgotrajno
                  brezposelnimi in osebami, ki so oddaljene od trga dela, tudi z
                  lokalnimi pobudami za zaposlovanje in spodbujanjem mobilnosti
                  delavcev in                 
                </li>
                <li>
                  8.1.1. specifičnega cilja: Povečanje zaposlenosti brezposelnih, še
                  posebej starejših od 50 let, dolgotrajno brezposelnih in tistih z
                  izobrazbo pod ISCED 3.
                </li>
              </ul>
            </div>
            <div>
              <h2>
                Naložbo sofinancirata Republika Slovenija in Evropska unija iz
                Evropskega socialnega sklada.
              </h2>
              <a href="https://www.eu-skladi.si/">
                <h2>www.eu-skladi.si</h2>
              </a>
            </div>
            <div className="eu-img">
              <img src={eu} />
            </div>
          </>
        }
        />
        
      </div>
      
    </div>
  );
}
