import React from 'react'
import { FaFacebookSquare } from "react-icons/fa";
import { FaInstagramSquare } from "react-icons/fa";
import { GoX } from 'react-icons/go';
import { GoListUnordered } from 'react-icons/go';
import './Navbar.css'
import { Link } from 'react-router-dom';
import { Component } from 'react';
import logo from "../IMAGES/marelad.jpg"

class Navbar extends Component {

    state = { clicked: false }

    handleClick = () => {
        this.setState({ clicked: !this.state.clicked })
    }

    render() {

        const MenuButton = () => {
            if (this.state.clicked) {
                return <GoX size={50} />
            }
            return <GoListUnordered size={50} />
        }

        return (
            <div className="navbar">
                <ul className={this.state.clicked ? "list-of-items active" : "list-of-items"}>
                    <Link to="/" style={{ textDecoration: 'none' }} onClick={this.handleClick}>
                        <li>
                            Domov
                        </li>
                    </Link>
                    <Link to="/o-osebni-asistenci" style={{ textDecoration: 'none' }} onClick={this.handleClick}>
                        <li>
                            O osebni asistenci
                        </li>
                    </Link>
                    <Link to="/sodelovanja" style={{ textDecoration: 'none' }} onClick={this.handleClick}>
                        <li>
                            Sodelovanja
                        </li>
                    </Link>
                    <Link to="/obvestila" style={{ textDecoration: 'none' }} onClick={this.handleClick}>
                        <li>
                            Obvestila
                        </li>
                    </Link>

                    <li className="list-of-items-icons">
                        <a href="https://www.facebook.com/Zavod-Marela-osebna-asistenca-100384545338131/" target="_blank" style={{ textDecoration: "none", color: 'inherit' }}>
                            <FaFacebookSquare size={35} />
                        </a>
                    </li>

                    <li className="list-of-items-icons">
                        <a href="https://www.instagram.com/zavod_marela_osebna_asistenca/" target="_blank" style={{ textDecoration: "none", color: 'inherit' }}>
                            <FaInstagramSquare size={35} />
                        </a>
                    </li>
                </ul>

                <div className='navbar-logo-text'>
                    <img src={logo}/>
                    <p>Zavod MARELA, Turnišče</p>
                </div>

                <div className='menu-icon' onClick={this.handleClick}>
                    {MenuButton()}
                </div>
            </div>
        )
    }
}

export default Navbar