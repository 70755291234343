import React from 'react'
import "./Domov.css"

export default function Domov() {
    return (
        <div className="domov">
            <div className="domov-items pages-items">
                <div className="osebna-izkaznica">
                    <h1>Osebna izkaznica</h1>
                    <p>MARELA – Zavod za izvajanje podpornih storitev za samostojno življenje, Turnišče</p>
                    <p><strong>Krajše ime:</strong> Zavod MARELA, Turnišče</p>
                    <p><strong>Naslov:</strong> Čevljarska ulica 25 A, 9224 Turnišče</p>
                    <p><strong>ID za DDV:</strong> 16961773</p>
                    <p><strong>Matična številka:</strong> 8601178000</p>
                    <p><strong>Št. poslovnega računa:</strong> SI56 0400 0027 6124 506, banka Nova KBM d. d. </p>
                </div>
                <div className="kontakt">
                    <h1>
                        Kontakt
                    </h1>
                    <p><strong>Mobitel:</strong> <br/> 041 680 141</p>
                   
                    <p><strong>E-mail:</strong> <br/> info@zavodmarela.si</p>
                    <p><strong>Spletna stran:</strong> <br/>zavodmarela.si</p>   
                </div>
            </div>
        </div>
    )
}
