import React, { useState } from 'react';
import "./ExpandMenu.css"

const ExpandMenu = ({ naslov, vsebina, jeOdprto, jeFiksen }) => {
  const [isOpen, setIsOpen] = useState(jeOdprto);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  
  return (
    <div className={isOpen ? 'expand_menu expand_menu_activated' : 'expand_menu'}>
        
        {jeFiksen?
            <div className='expand_menu_text'>
                <span> {naslov}</span>
            </div>:

            <div className={isOpen ? 'expand_menu_text expand_menu_button expand_menu_button_activated' : 'expand_menu_button expand_menu_text'} onClick={toggleMenu}>
                <h2> {isOpen ? '⏷' : '⏵'} </h2>
                <span> {naslov}</span>
            </div>
        }
        
        {isOpen && (
        <>
        <p className='vsebina_expand_menu'>{vsebina}</p>
        </>
        )}
    </div>
  );
};

export default ExpandMenu;
