import React, {useState, useRef, useEffect} from "react"
import "./App.css"
import Header from "./components/Header"
import Navbar from "./components/Navbar"
import Footer from "./components/Footer"
import Domov from "./pages/Domov"
import OOsAsistenci from "./pages/OOsAsistenci"
import Obvestila from "./pages/Obvestila"
import Sodelovanja from "./pages/Sodelovanja"
import { Route, Routes } from 'react-router-dom'

function App() 
{
  return(
      <>
        <div>
            <Header/>
            <Navbar/>
            <Routes>
                <Route path="/" exact element={<Domov/>}/>
                <Route path="/o-osebni-asistenci" exact element={<OOsAsistenci/>}/>
                <Route path="/sodelovanja" exact element={<Sodelovanja/>}/>
                <Route path="/obvestila" exact element={<Obvestila/>}/>
                <Route path="*" element={<Domov/>}/>
            </Routes>
            <Footer/>
        </div>
          
      </>
  )
}

export default App
