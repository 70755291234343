import React from 'react'
import "./Header.css"
import logo from "../IMAGES/marelad.jpg"

export default function Header() {
    return (
        <>
            <div className="header">
                <div className="header-image">
                    <img src={logo} alt="logo"/>
                </div>
                <div className="header-text">
                    <span className="header-text-marela">MARELA</span> - zavod za izvajanje podpornih storitev za samostojno življenje, Turnišče
                </div>
            </div>
        </>
    )
}